/**
 * Created by instopunk on 03.01.2019.
 */

import { combineReducers } from 'redux'
import users from './users'

export default combineReducers({
  users
})
