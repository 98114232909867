/**
 * Created by instopunk on 03.01.2019.
 */

import React from 'react'
import Calculator from '../../components/Calculator'

const CalculatorContainer = ({ users }) => {

  return (
    <Calculator
      users={users}
    />
  )
}

export default CalculatorContainer
