/**
 * Created by instopunk on 03.01.2019.
 */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  addUser,
  updateUser,
  removeUser
} from '../../actions/users'
import UsersList from '../../components/UsersList'

const Users = ({ users, addUser, updateUser, removeUser }) => {

  return (
    <UsersList
      users={users}
      addUser={addUser}
      updateUser={updateUser}
      removeUser={removeUser}
    />
  )
}

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators({
    addUser: addUser,
    updateUser: updateUser,
    removeUser: removeUser
  }, dispatch)
)(Users)
