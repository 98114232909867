/**
 * Created by instopunk on 03.01.2019.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import User from '../User'
import Button from '@material-ui/core/Button'
import './styles.scss'

class UsersList extends Component {

  handleAdd = () => {
    const {addUser} = this.props

    addUser();
  }

  render () {

    const { users, updateUser, removeUser } = this.props

    return (
      <div className="users-list">
        {
          users.map((user, key) => (
            <User
              user={user}
              updateUser={updateUser}
              removeUser={removeUser}
              index={key}
              canBeRemoved={users.length > 2}
              key={key}
            />
          ))
        }
        <Button
          variant="contained"
          onClick={this.handleAdd}
        >
          Добавить
        </Button>
      </div>
    )
  }
}

UsersList.propTypes = {
  users: PropTypes.array.isRequired
}

export default UsersList
