/**
 * Created by instopunk on 03.01.2019.
 */

import lzutf8 from 'lzutf8'
import lStorage from 'local-storage'

const usersInitialState = [
  {
    name: '',
    placeholder: 'User 1',
    payments: [
      {
        price: ''
      }
    ]
  },
  {
    name: '',
    placeholder: 'User 2',
    payments: [
      {
        price: ''
      }
    ]
  }
]

const shUsersInitialState = window.location.hash && JSON.parse(lzutf8.decompress(window.location.hash.replace('#',''), { inputEncoding: 'Base64' }))
const lsUsersInitialState = lStorage.get('users')

const users = (state = shUsersInitialState || lsUsersInitialState || usersInitialState, action) => {
  switch (action.type) {

    case 'ADD_USER': {
      const newAddState = [
        ...state,
        {
          name: '',
          placeholder: `New user ${state.length +1}`,
          payments: [
            {
              price: ''
            }
          ]
        }
      ]
      lStorage.set('users', newAddState)
      return newAddState
    }

    case 'UPDATE_USER':
      const {index, user} = action
      let newState = [...state]
      newState.splice(index, 1, user)
      lStorage.set('users', newState)
      return newState

    case 'REMOVE_USER':
      const remIndex = action.index
      let newRemState = [...state]
      newRemState.splice(remIndex, 1)
      lStorage.set('users', newRemState)
      return newRemState

    default:
      return state
  }
}

export default users
