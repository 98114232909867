import React from 'react'
import { connect } from 'react-redux'
import logo from './logo.svg'
import './App.css'
import Users from './containers/Users'
import CalculatorContainer from './containers/Calculator'
import Share from './components/Share'

const App = ({ users }) =>  (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo"/>
      <Share users={users}/>
    </header>
    <Users users={users}/>
    <CalculatorContainer users={users}/>
  </div>
)

export default connect(
  (state) => ({
    users: state.users
  })
)(App)
