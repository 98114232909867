/**
 * Created by instopunk on 03.01.2019.
 */

export const addUser = () => ({
  type: 'ADD_USER'
})

export const updateUser = ( index, user ) => ({
  type: 'UPDATE_USER',
  index: index,
  user: user
})

export const removeUser = ( index ) => ({
  type: 'REMOVE_USER',
  index: index
})
