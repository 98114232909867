/**
 * Created by instopunk on 03.01.2019.
 */

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const reducer = (accumulator, currentValue) => parseFloat(accumulator.price || accumulator) + (parseFloat(currentValue.price) || 0)
const sumReducer = (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue)

class Calculator extends Component {

  paymentsSum = (payments) => {
    if (payments.length > 1) {
      const sum = payments.reduce(reducer)
      return isNaN(sum) ? 0 : sum
    }

    const sum = parseFloat(payments[0].price)
    return isNaN(sum) ? 0 : sum
  }

  sum = (payments) => {
    if (payments.length > 1)
      return payments.reduce(sumReducer)

    const price = parseFloat(payments[0])

    return isNaN(price) ? 0 : price
  }

  render() {

    const {users} = this.props
    let sums = []

    users.map((user, key) => {
      sums.push(this.paymentsSum(user.payments))
      return user
    })

    const allSum = this.sum(sums)
    const perUser = (allSum / users.length).toFixed(2)

    //console.log(allSum)

    return (
      <div className="calculator">
        {
          users.map((user, key) => {
            return (
              <div key={key}>
                <span className="calculator__username">
                  {user.name || user.placeholder} =&nbsp;
                </span>
                {sums[key].toLocaleString()} ₽
              </div>
            )
          })
        }
        <div>
          Общая сумма = {allSum.toLocaleString()} ₽
        </div>
        <div>
          С каждого = {perUser.toLocaleString()} ₽
        </div>
        <hr/>
        {
          users.map((user, key) => {
            return (
              <div key={key}>
                {
                  users.map((secondUser, secondKey) => {
                    if (key === secondKey)
                      return null

                    const pay = Math.max(Math.min(perUser - sums[key], (sums[secondKey] - perUser)), 0)
                    sums[key] = sums[key] + pay
                    sums[secondKey] = sums[secondKey] - pay

                    return pay > 0 ? (
                      <div key={secondKey}>
                        <span className="calculator__username">
                          {
                            `
                            ${user.name || user.placeholder} ->
                            ${secondUser.name || secondUser.placeholder}
                            `
                          }
                        </span> =&nbsp;
                        {
                          `
                          ${pay.toFixed(2).toLocaleString()} ₽
                          `
                        }
                      </div>
                    ) : null
                  })
                }
              </div>
            )
          })
        }
      </div>
    )
  }
}

Calculator.propTypes = {
  users: PropTypes.array.isRequired
}

export default Calculator
