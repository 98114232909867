/**
 * Created by instopunk on 03.01.2019.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'

class User extends Component {

  handleNameChange = event => {
    const { index, user, updateUser } = this.props

    const newUser = {
      ...user,
      name: event.target.value
    }

    updateUser(index, newUser)
  }

  handleAddPayment = event => {
    const { index, user, updateUser } = this.props

    const newUser = {
      ...user,
      payments: [
        ...user.payments,
        {
          price: ''
        }
      ]
    }

    updateUser(index, newUser)
  }

  handleRemoveUser = event => {
    const { index, removeUser } = this.props

    removeUser(index)
  }

  handlePriceChange = ( event, pIndex ) => {
    const { index, user, updateUser } = this.props

    let newPayments = [...user.payments]
    newPayments.splice(pIndex, 1, {
      price: event.target.value
    })

    const newUser = {
      ...user,
      payments: newPayments
    }

    updateUser(index, newUser)
  }

  handleRemovePayment = ( rIndex ) => {
    const { index, user, updateUser } = this.props

    let newPayments = [...user.payments]
    newPayments.splice(rIndex, 1)

    const newUser = {
      ...user,
      payments: newPayments
    }

    updateUser(index, newUser)
  }

  render () {

    const { user, canBeRemoved } = this.props
    const { placeholder, name, payments } = user

    return (
      <Card className="users-list__item">
        {
          canBeRemoved
            ? (
              <Button className="users-list__item-remove" onClick={this.handleRemoveUser}>
                Удалить
              </Button>
            )
            : null
        }

        < div className="users-list__item-row">
          < Input
            className="users-list__item-name"
            placeholder={placeholder}
            value={name}
            onChange={this.handleNameChange}
          />
        </div>
        {
          payments.map(( payment, key ) => (
            <div className="users-list__item-row" key={key}>
              <Input
                placeholder="0"
                value={payment.price}
                onChange={( event ) => {
                  this.handlePriceChange(event, key)
                }}
              />
              {
                payments.length > 1
                  ? (
                    <div className="users-list__item-remove-payment" onClick={( event ) => {
                      this.handleRemovePayment(key)
                    }}/>
                  )
                  : null
              }
            </div>
          ))
        }
        <div>
          <Button onClick={this.handleAddPayment}>
            Добавить платёж
          </Button>
        </div>
      </Card>
    )
  }
}

User.propTypes = {
  user: PropTypes.object.isRequired
}

export default User
