/**
 * Created by instopunk on 03.01.2019.
 */

import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import lzutf8 from 'lzutf8'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './styles.scss'

const Share = ({ users }) => {
  const
    hash = lzutf8.compress(JSON.stringify(users), { outputEncoding: 'Base64' }),
    link = `${window.location.origin}#${hash}`

  const [copied, setCopied] = useState(false)

  if(copied){
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className="share">
      <CopyToClipboard
        text={link}
        onCopy={() => setCopied(true)}
      >
        <Button variant="outlined" color="secondary" href={link}>
          Поделиться
        </Button>
      </CopyToClipboard>
      {
        copied &&
        <p>
          Скопировано в буфер обмена
        </p>
      }
    </div>
  )
}

export default Share
